import * as React from 'react';

import { GeneralSettingsContext } from '@app/Settings/General/GeneralSettings';
import {
  Alert,
  AlertActionCloseButton,
  AlertVariant,
  Button,
  EmptyState,
  EmptyStateBody,
  EmptyStateIcon,
  EmptyStateVariant,
  PageSection,
  Split,
  SplitItem,
  Stack,
  StackItem,
  Text,
  Title,
} from '@patternfly/react-core';
import { User, UserType, Class } from '@buf/sphere_edu.bufbuild_es/edu/v1/edu_types_pb';
import { useParams, useHistory, Link } from 'react-router-dom';
import { TableComposable, Tbody, Td, Th, Thead, Tr, IAction, ActionsColumn } from '@patternfly/react-table';
import { EduService } from '@buf/sphere_edu.connectrpc_es/edu/v1/edu_connect';
import { createPromiseClient } from '@connectrpc/connect';
import { createConnectTransport } from '@connectrpc/connect-web';
import { DeleteUserRequest } from '@mergetb/api/portal/v1/workspace_types';
import { UnregisterRequest } from '@mergetb/api/portal/v1/identity_types';
import { SearchIcon } from '@patternfly/react-icons';

type ManageUsersProp = {
  classId: string;
};

type UserState = {
  identityTypeString: string;
};

const renderEmptyState = (dataType: string, classId: string) => (
  <EmptyState variant={EmptyStateVariant.xs}>
    <EmptyStateIcon icon={SearchIcon} />
    <Title headingLevel="h5" size="lg">
      {`No ${dataType}s available`}
    </Title>
    <EmptyStateBody>
      {`There are currently no ${dataType}s to display. `}
      <Link to={`/edu/class/${classId}/addusers`}>Add some</Link>
    </EmptyStateBody>
  </EmptyState>
);

const ManageUsers: React.FunctionComponent = () => {
  const { classId } = useParams<ManageUsersProp>();
  const history = useHistory();
  const conf = React.useContext(GeneralSettingsContext);

  const client = React.useMemo(() => {
    const transport = createConnectTransport({
      baseUrl: `${conf.eduApi}`,
      credentials: 'include',
    });
    return createPromiseClient(EduService, transport);
  }, [conf.eduApi]);

  const [users, setUsers] = React.useState<User[] | undefined>(undefined);
  const [c, setC] = React.useState<Class | undefined>(undefined);
  const [reload, setReload] = React.useState<boolean>(true);

  const [alert, setAlert] = React.useState<typeof Alert | undefined>();

  const cols = {
    username: 'Username',
    name: 'Full Name',
    email: 'Email',
    password: 'Password',
  };

  React.useMemo(async () => {
    await client
      .getClass({
        classId: classId,
      })
      .then((res) => {
        setC(res?.class);
      })
      .catch((e) => console.log('error getting class', e));
  }, [client, classId]);

  const recycleUsers = async (users: string[]) => {
    const gone = users.filter((u) => c?.users[u] == UserType.STUDENT || c?.users[u] == UserType.TA);

    const resp = await client.removeUsersFromClass({ classId: classId, userIds: gone });
    console.log('remove users from class resp', resp);

    // in parallel
    await Promise.all(
      gone.map(async (u: string) => {
        const resp = await client.deleteUser({ userId: u });
        console.log('delete user resp', resp);
      })
    );

    // in sequence - portal does not like in parallel
    for (const user of gone) {
      const success = await portalDeleteUser(user, conf);
      if (!success) {
        // TODO handle this
        console.log('error deleting portal user');
      }
    }

    setReload(true);
    setAlert(undefined);
  };

  React.useMemo(async () => {
    if (reload) {
      await client
        .getClassUsers({
          classId: classId,
        })
        .then((res) => {
          setUsers(res?.users);
        })
        .catch((e) => console.log('error getting class users', e));
      setReload(false);
    }
  }, [classId, client, reload]);

  const actions = (u: User): IAction[] => [
    {
      title: 'Edit user information',
      onClick: () => {
        const state: UserState = { identityTypeString: userTypeString(c?.users[u.userId]) };
        history.push(`/edu/user/${u.userId}`, state);
      },
    },
    {
      title: 'Recycle user',
      onClick: () => recycleUsers([u.userId]),
    },
  ];

  const recycleAllUsers = () => {
    if (c) {
      setAlert(
        <Alert
          isToast
          variant={AlertVariant.warning}
          title={'Recyling all users'}
          actionClose={<AlertActionCloseButton onClose={() => setAlert(undefined)} />}
        />
      );
      recycleUsers(Object.keys(c.users));
    }
  };

  return (
    <React.Fragment>
      <PageSection>
        {alert}
        <Stack hasGutter>
          <StackItem>
            <Split hasGutter>
              <SplitItem>
                <Title headingLevel="h1">Manage Class {classId} Users</Title>
              </SplitItem>
              <SplitItem isFilled />
              <SplitItem>
                <Button title="Add Users" onClick={() => history.push(`/edu/class/${classId}/addusers`)}>
                  Add Users
                </Button>
              </SplitItem>
              <SplitItem>
                <Button title="Recycle Users" variant="danger" onClick={() => recycleAllUsers()}>
                  Recycle All Users
                </Button>
              </SplitItem>
            </Split>
          </StackItem>
          <StackItem>
            <Title headingLevel="h3">Professors</Title>
            <TableComposable variant="compact" borders={false} aria-label="Manage Professors">
              <Thead>
                <Tr>
                  <Th>{cols.username}</Th>
                  <Th>{cols.name}</Th>
                  <Th>{cols.email}</Th>
                  <Th>{cols.password}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {c && users?.filter((u) => c.users[u.userId] == UserType.PROFESSOR).length == 0 && (
                  <Tr>
                    <Td colSpan={Object.keys(cols).length}>{renderEmptyState('professor', classId)}</Td>
                  </Tr>
                )}
                {c &&
                  users?.map((u) => {
                    if (c.users[u.userId] == UserType.PROFESSOR) {
                      return (
                        <Tr key={u.userId}>
                          <Td dataLabel={cols.username}>{u.userId}</Td>
                          <Td dataLabel={cols.name}>
                            {u.fullName || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td dataLabel={cols.email}>
                            {u.email || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td dataLabel={cols.password}>
                            {u.password || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td isActionCell>
                            <ActionsColumn items={actions(u)} />
                          </Td>
                        </Tr>
                      );
                    }
                    return <></>;
                  })}
              </Tbody>
            </TableComposable>
          </StackItem>
          <StackItem>
            <Title headingLevel="h3">Teaching Assistants</Title>
            <TableComposable variant="compact" borders={false} aria-label="Manage TAs">
              <Thead>
                <Tr>
                  <Th>{cols.username}</Th>
                  <Th>{cols.name}</Th>
                  <Th>{cols.email}</Th>
                  <Th>{cols.password}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {c && users?.filter((u) => c.users[u.userId] == UserType.TA).length == 0 && (
                  <Tr>
                    <Td colSpan={4}>{renderEmptyState('TA', classId)}</Td>
                  </Tr>
                )}
                {c &&
                  users?.map((u) => {
                    if (c.users[u.userId] == UserType.TA) {
                      return (
                        <Tr key={u.userId}>
                          <Td dataLabel={cols.username}>{u.userId}</Td>
                          <Td dataLabel={cols.name}>
                            {u.fullName || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td dataLabel={cols.email}>
                            {u.email || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td dataLabel={cols.password}>
                            {u.password || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td isActionCell>
                            <ActionsColumn items={actions(u)} />
                          </Td>
                        </Tr>
                      );
                    }
                    return <></>;
                  })}
              </Tbody>
            </TableComposable>
          </StackItem>
          <StackItem>
            <Title headingLevel="h3">Students</Title>
            <TableComposable variant="compact" borders={false} aria-label="Manage Students">
              <Thead>
                <Tr>
                  <Th>{cols.username}</Th>
                  <Th>{cols.name}</Th>
                  <Th>{cols.email}</Th>
                  <Th>{cols.password}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {c && users?.filter((u) => c.users[u.userId] == UserType.STUDENT).length == 0 && (
                  <Tr>
                    <Td colSpan={4}>{renderEmptyState('student', classId)}</Td>
                  </Tr>
                )}
                {c &&
                  users?.map((u) => {
                    if (c.users[u.userId] == UserType.STUDENT) {
                      return (
                        <Tr key={u.userId}>
                          <Td dataLabel={cols.username}>{u.userId}</Td>
                          <Td dataLabel={cols.name}>
                            {u.fullName || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td dataLabel={cols.email}>
                            {u.email || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td dataLabel={cols.password}>
                            {u.password || <Text style={{ color: 'lightgray' }}>{'(empty)'}</Text>}
                          </Td>
                          <Td isActionCell>
                            <ActionsColumn items={actions(u)} />
                          </Td>
                        </Tr>
                      );
                    }
                    return <></>;
                  })}
              </Tbody>
            </TableComposable>
          </StackItem>
        </Stack>
      </PageSection>
    </React.Fragment>
  );
};

const userTypeString = (t: UserType | undefined): string => {
  switch (t) {
    case UserType.UNSPECIFIED: {
      return 'Unspecified';
    }
    case UserType.STUDENT: {
      return 'Student';
    }
    case UserType.PROFESSOR: {
      return 'Professor';
    }
    case UserType.TA: {
      return 'Teaching Assistant';
    }
    case UserType.ADMIN: {
      return 'Admin';
    }
    default:
      return 'Unknown';
  }
};

const portalDeleteUser = async (username: string, conf): Promise<boolean> => {
  let status = true;

  const delReq = DeleteUserRequest.fromJSON({
    user: username,
  });
  const resp = await fetch(`${conf.api}/user/${username}`, {
    method: 'DELETE',
    credentials: 'include',
    body: JSON.stringify(delReq),
  }).catch((e) => {
    // TODO: unregister the user!
    console.log('delete user error', e);
    status = false;
  });

  if (resp) {
    if (!resp.ok) {
      const t = await resp.text();
      throw new Error(t);
    }
  }

  if (!status) return status;

  const unReq = UnregisterRequest.fromJSON({ username: username });
  await fetch(`${conf.api}/unregister/${username}`, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(unReq),
  }).catch((e) => {
    // TODO: unregister the user!
    console.log('unregister error', e);
    status = false;
  });

  return status;
};

export { ManageUsers };
